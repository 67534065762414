<template>
    <div class="shop-selection-goods">
        <!-- 主体 -->
        <CompContent title="添加选品">
            <!-- 头部搜索层 -->
            <template #operate>
                <Button class="btn" type="primary" icon="ios-search" @click="onSearch">查询</Button>
                <Button class="btn" type="success" icon="ios-refresh" @click="onReset">重置</Button>
                <Button type="info" @click="selecteds = selecteds.length === commoditys.length ? [] : commoditys">全选当前页商品</Button>
                <Button type="primary" @click="isDisplaySelected = true">查看已选商品</Button>
            </template>
            <!-- 内容 -->
            <template>
                <div class="content-box" @scroll="onContentScroll">
                    <div class="search-box">
                        <div class="search-item">
                            <div class="title">搜索：</div>
                            <div class="elem-box">
                                <Input v-model.trim="search.search_words" placeholder="输入商品名称、品牌、商品ID" style="width: 250px"></Input>
                            </div>
                        </div>
                        <div class="search-item">
                            <div class="title">筛选条件：</div>
                            <div class="elem-box">
                                <Select v-model="search.status" placeholder="选择商品状态" filterable style="width:200px" transfer>
                                    <Option :value="0">下架</Option>
                                    <Option :value="1">上架</Option>
                                </Select>
                                <Select v-model="search.is_free_shipping" placeholder="选择配送状态" filterable style="width:200px" transfer>
                                    <Option :value="0">不包邮</Option>
                                    <Option :value="1">包邮</Option>
                                </Select>
                            </div>
                        </div>
                        <div class="search-item">
                            <div class="title">筛选区间：</div>
                            <div class="elem-box">
                                <ElemSection v-model="search.agreement_price" title="协议价"></ElemSection>
                                <ElemSection v-model="search.guide_price" title="指导价"></ElemSection>
                                <ElemSection v-model="search.activity_price" title="营销价"></ElemSection>
                                <ElemSection v-model="search.activity_rate" title="利润率"></ElemSection>
                            </div>
                        </div>
                    </div>
                    <div class="sort-box" :class="sort">
                        <div class="item not-sort-type" :class="{ ac: sort_type === 'created_time' }" @click="onChangeSort('created_time', false)">最新上架</div>
                        <div class="item" :class="{ ac: sort_type === 'agreement_price' }" @click="onChangeSort('agreement_price')">协议价</div>
                        <div class="item" :class="{ ac: sort_type === 'promotion_rate' }" @click="onChangeSort('promotion_rate')">利润率</div>
                        <div class="item" :class="{ ac: sort_type === 'guide_price' }" @click="onChangeSort('guide_price')">指导价</div>
                    </div>
                    <div class="commodity-box">
                        <div class="commodity-item" v-for="item in commoditys" :class="{ selected: selecteds.findIndex(v => v.id === item.id) > -1 }" :key="item.id" @click="onSelectItem(item)">
                            <div class="select-box">
                                <Icon class="icon" type="md-checkmark" color="#4f96c2" />
                            </div>
                            <div class="item-box">
                                <div class="photo">
                                    <div class="img-box" :style="{ 'background-image': 'url(' + item.cover + ')' }"></div>
                                </div>
                                <p class="name">{{ item.title }}</p>
                                <div class="info">
                                    <div class="item">
                                        <p class="name">协议价</p>
                                        <p class="value">￥{{ (item.agreement_price / 100).toFixed(2) }}</p>
                                    </div>
                                    <div class="item">
                                        <p class="name">利润率</p>
                                        <p class="value">{{ item.activity_rate }}%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="empty" v-if="commoditys && commoditys.length === 0">当前商品列表为空</p>
                    </div>
                </div>
            </template>
        </CompContent>

        <div class="selected-base" v-show="isDisplaySelected" @click="isDisplaySelected = false">
            <div class="selected-box" @click.stop>
                <div class="cart-box">
                    <div class="cart-card" v-for="item in selecteds" :key="item.id" @tap="jump('/apps/suppliershop/pages/goods/detail?goodsId=' + item.goodsId)">
                        <div class="card-centre">
                            <div class="card-centre-img">
                                <img class="img" :src="item.cover" />
                                <div class="out-stock" v-if="item.serviceType === 'goods' && item.goodsNumber <= 0"></div>
                            </div>
                            <div class="card-centre-content">
                                <div class="content-text">{{ item.title }}</div>
                                <div class="price">
                                    <div class="item">
                                        协议价
                                        <p class="p">￥{{ (item.agreement_price / 100).toFixed(2) }}</p>
                                    </div>
                                    <div class="item">
                                        利润率
                                        <p class="p">{{ item.activity_rate }}%</p>
                                    </div>
                                    <div class="item">
                                        指导价
                                        <p class="p">￥{{ (item.guide_price / 100).toFixed(2) }}</p>
                                    </div>
                                    <!-- <div class="item">
                                        折扣
                                        <p class="p">￥{{ (item.sale_price / 1000).toFixed(2) }}</p>
                                    </div> -->
                                </div>
                            </div>
                            <div class="close" @click="onSelectItem(item)">
                                <Icon type="md-close" />
                            </div>
                        </div>
                    </div>

                    <div class="empty-box" v-if="selecteds && selecteds.length <= 0">
                        请先勾选所需的商品
                    </div>
                </div>

                <div class="btns-box">
                    <Button class="btn" @click="Route.jump('/shopselectiongroup')">我的选品库</Button>
                    <Button class="btn" @click="Route.jump('/shopselectiongroup')">创建分组</Button>
                    <Button class="btn" type="primary" @click="onWarehousing">加入选品库</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import RequestPage from "../../../jointly/utils/page"
import Request from "../../../jointly/utils/request"
import CompContent from "../../../customform/components/CompContent.vue"
import ElemSection from "../../../customform/components/ElemSection.vue"

export default {
    components: {
        CompContent,
        ElemSection,
    },

    data() {
        return {
            Route: Route,
            commoditys: [],
            selecteds: [],
            // 显示已选
            isDisplaySelected: false,
            // 搜索条件
            search: {},
            // 页码
            page: 1,
            // 页面大小
            pageSize: 50,
            // 排序类型
            sort: null,
            // 排序字段
            sort_type: null,
        }
    },

    async mounted() {
        this.categoryId = this.$core.getUrlParam("c")
        this.getCommoditys()
    },

    methods: {
        getCommoditys() {
            if (this.page !== 1 && this.isLoaded) {
                return
            }

            this.isLoaded = false

            Request.post(
                "/gateway/apps/o2o/api/o2o/qianfan/searchGoods",
                {
                    page: this.page,
                    limit: this.pageSize,
                    type: this.sort_type,
                    sort: this.sort,
                    ...this.search,
                },
                {
                    json: true,
                    response: res => res.code === 1,
                }
            ).then(res => {
                this.commoditys = this.page === 1 ? res.list : [].concat(this.commoditys, res.list)
                this.isLoaded = this.page * this.pageSize > res.count
            })
        },

        onSelectItem(item) {
            let s = this.selecteds
            let idx = s.findIndex(v => v.id === item.id)

            if (idx > -1) {
                s.splice(idx, 1)
            } else {
                s.push(item)
            }
        },

        onWarehousing() {
            if (this.selecteds.length === 0) {
                return this.$Message.error("请至少选择一个商品")
            }

            Request.post(
                "/gateway/apps/o2o/api/o2o/qianfan/goodsJoin",
                {
                    categoryId: this.categoryId, //分类id
                    goodsIds: this.selecteds.map(v => v.id), //商品id
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("加入成功")

                setTimeout(() => {
                    Route.back()
                }, 2000)
            })
        },

        onSearch() {
            this.page = 1
            this.getCommoditys()
        },

        onReset() {
            this.search = {}
            this.page = 1
            this.getCommoditys()
        },

        onContentScroll(evt) {
            const t = evt.target
            if (t.scrollHeight - 50 < t.scrollTop + t.clientHeight) {
                if (this.isTouchGround) return
                this.isTouchGround = true
                this.page++
                this.getCommoditys()
            } else {
                this.isTouchGround = false
            }
        },

        onChangeSort(type, sort = true) {
            if (sort) {
                if (this.sort_type === type) {
                    if (this.sort === "desc") {
                        this.sort = null
                        this.sort_type = null
                        this.page = 1
                        this.getCommoditys()
                        return
                    } else {
                        this.sort = "desc"
                    }
                } else {
                    this.sort = "asc"
                }
            } else {
                this.sort = null
            }

            this.sort_type = type
            this.page = 1

            this.getCommoditys()
        },
    },
}
</script>

<style lang="less">
.shop-selection-goods {
    .toolsbarRight {
        align-items: center;

        div,
        button {
            margin: 5px 0 5px 8px !important;
        }
    }

    .content-box {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        background: #f9f9f9;
    }

    .search-box {
        margin: 10px 20px;
        padding: 0 10px;
        background: #fff;
        border-radius: 8px;

        .search-item {
            padding: 10px 10px 10px 0;
            display: flex;
            width: 100%;
            border-bottom: 1px solid #f3f3f3;

            &:last-child {
                border-bottom: 0;
            }

            > .title {
                line-height: 32px;
                margin: 2px 10px 2px 10px;
                flex-shrink: 0;
                font-size: 13px;
                color: #888;
            }

            .elem-box {
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;

                > div {
                    margin: 2px 20px 2px 0;
                }

                .item {
                    margin-right: 20px;

                    > div {
                        margin-top: 4px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .sort-box {
        padding: 10px 0;
        margin: 10px 20px;
        background: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;

        .item {
            cursor: pointer;
            position: relative;
            padding: 0 40px 0 30px;
            border-right: 1px solid #f3f3f3;
            font-size: 13px;
            color: #9f9f9f;

            &:last-child {
                border-right: 0;
            }

            &::after {
                content: "";
                position: absolute;
                top: 1px;
                right: 24px;
                border-top: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 4px solid #cdcdcd;
                border-left: 4px solid transparent;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 1px;
                right: 24px;
                border-top: 4px solid #cdcdcd;
                border-right: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 4px solid transparent;
            }

            &.ac {
                color: #333;
            }

            &.not-sort-type {
                padding-right: 30px;

                &::after,
                &::before {
                    display: none;
                }
            }
        }

        &.desc {
            .ac::after {
                border-bottom-color: #777777;
            }
        }

        &.asc {
            .ac::before {
                border-top-color: #777777;
            }
        }
    }

    .commodity-box {
        width: 100%;
        padding: 0 10px 10px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;

        .commodity-item {
            position: relative;
            width: calc(100% / 4 - 12px);
            margin: 6px;

            @media (min-width: 1200px) {
                width: calc(100% / 6 - 12px);
            }

            @media (min-width: 1800px) {
                width: calc(100% / 7 - 12px);
            }

            @media (min-width: 2000px) {
                width: calc(400px - 12px);
            }

            .select-box {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 20px;
                height: 20px;
                border: 2px solid #888;
                border-radius: 6px;
                z-index: 10;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    visibility: hidden;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .item-box {
                width: calc(100% - 2px);
                border: 2px solid #f3f3f3;
                border-radius: 8px;
                overflow: hidden;
                background: #fff;

                // &:hover {
                //     border-color: #e3e3e3;
                //     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                // }

                .photo {
                    position: relative;
                    width: 100%;
                    padding-bottom: 100%;
                    overflow: hidden;
                    border-bottom: 1px solid #f3f3f3;

                    .img-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        transition: all 0.3s ease;
                    }
                }

                > .name {
                    color: #333;
                    margin: 10px 10px 10px 10px;
                    text-align: center;
                    height: 44px;
                    line-height: 22px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -moz-box;
                    -moz-line-clamp: 2;
                    -moz-box-orient: vertical;
                    overflow-wrap: break-word;
                    word-break: break-all;
                    white-space: normal;
                    overflow: hidden;
                }

                .info {
                    padding: 10px;
                    display: flex;
                    height: 70px;
                    border-top: 1px solid #f3f3f3;

                    .item {
                        flex: 1;
                        border-right: 1px solid #f3f3f3;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        &:last-child {
                            border-right: 0;
                        }

                        .name {
                            color: #888;
                            font-size: 12px;
                        }

                        .value {
                            color: #2d8cf0;
                            font-size: 14px;
                        }
                    }
                }
            }

            &.selected {
                .item-box {
                    border-color: #ecb7b7;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }

                .select-box {
                    .icon {
                        visibility: initial;
                    }
                }
            }
        }

        .empty {
            text-align: center;
            width: 100%;
            margin-top: 50px;
            color: #888;
            font-size: 14px;
        }
    }

    .selected-base {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 30;

        .selected-box {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 400px;
            background: #f9f9f9;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            border-radius: 10px 0 0 10px;
            overflow: hidden;

            .cart-box {
                width: 100%;
                height: calc(100% - 62px);

                overflow-y: auto;
            }

            .cart-card {
                display: flex;
                padding: 10px;
                margin: 10px;
                background: #fff;
                border-radius: 10px;

                .card-centre {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    overflow: hidden;

                    .card-centre-img {
                        position: relative;
                        width: 80px;
                        height: 80px;
                        flex-shrink: 0;
                        border-radius: 6px;
                        overflow: hidden;

                        .img {
                            width: 100%;
                            height: 100%;
                        }

                        .out-stock {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: rgba(150, 150, 150, 0.5);

                            &::after {
                                content: "无货";
                                position: absolute;
                                top: 30px;
                                left: 30px;
                                right: 30px;
                                bottom: 30px;
                                background: rgba(62, 62, 62, 0.8);
                                border-radius: 50%;
                                color: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }

                    .card-centre-content {
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 10px;
                        margin: 5px 0;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        overflow: hidden;

                        .content-text {
                            display: -webkit-box;
                            width: 100%;
                            font-size: 14px;
                            color: #333;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            word-break: break-all;
                        }

                        .price {
                            margin-top: 4px;
                            width: 100%;
                            box-sizing: border-box;
                            vertical-align: bottom;
                            display: flex;
                            flex-wrap: wrap;
                            flex-grow: 1;
                            align-items: flex-end;

                            .item {
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                margin-right: 15px;
                                margin-top: 2px;
                                color: #999;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                .p {
                                    color: red;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }

                    .close {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px 10px 5px 10px;
                        font-size: 20px;
                        margin-left: 10px;
                    }
                }
            }

            .empty-box {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .btns-box {
                position: absolute;
                bottom: 0;
                width: 100%;
                background: #fff;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

                .btn {
                    width: calc(33.33% - 5px);
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>
